var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DashboardShareOverlayStyled', [_vm.$apollo.loading ? _c('TalpaLoaderWrapper') : _c('Modal', {
    staticClass: "modal",
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "uppercase"
        }, [_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t('actions.share'))))]), _c('SearchBar', {
          attrs: {
            "searchQuery": _vm.searchQuery,
            "placeHolder": 'dashboard.attributes.shareDashboardWith'
          },
          on: {
            "filterShareList": _vm.usersListFiltered
          }
        }), _c('HorizontalTabs', {
          attrs: {
            "tabs": _vm.tabs
          },
          on: {
            "activateTab": _vm.activateTab
          }
        })];
      },
      proxy: true
    }, {
      key: "main",
      fn: function fn() {
        return [_vm.selectedUsersList.length < 1 ? _c('NoDataStyled', [_vm._v(" " + _vm._s(_vm.$t('dashboard.attributes.noUsersFound')) + " ")]) : _vm._l(_vm.selectedUsersList, function (data) {
          return _c('DashboardSharableList', {
            key: data.id,
            attrs: {
              "user": data,
              "dashboardID": _vm.dashboardID
            }
          });
        })];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }