<template>
  <OwnerBadgeStyled>
    {{ $t('dashboard.owner') }}
  </OwnerBadgeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { flexCenter } from '@styles/mixins'

const OwnerBadgeStyled = styled('div')`
  ${flexCenter}
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.atomic.white};
  border-radius: 18px;
  padding: 0.25rem;
  font-size: 12px;
`

export default {
  components: {
    OwnerBadgeStyled,
  },
}
</script>
