<template>
  <HorizontalTabsStyled>
    <TabStyled v-for="tab in tabs" :key="tab.id" :isSelected="tab.isSelected" @click="activateTab(tab.id)">
      <span> {{ $t(`dashboard.attributes.` + tab.name) }} </span>
      <span v-if="tab.count"> ({{ tab.count }}) </span>
    </TabStyled>
  </HorizontalTabsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const HorizontalTabsStyled = styled('div')`
  display: flex;
  width: 100%;
  padding: 1rem 0.25rem 0rem 0.25rem;
  > div {
    margin-right: 1rem;
  }
`
const TabStyled = styled('div')`
  cursor: pointer;
  user-select: none;
  padding: 0.25rem;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.atomic.textMain};
  border-bottom: 3px solid ${p => (p.isSelected ? p.theme.colors.primary : p.theme.colors.atomic.arrowWrapperBg)};
`
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
  components: {
    HorizontalTabsStyled,
    TabStyled,
  },
  methods: {
    activateTab(tab) {
      this.$emit('activateTab', tab)
    },
  },
}
</script>
