<template>
  <SearchStyled>
    <input v-model="localSearchQuery" v-debounce:300ms="localSearchQuery" :placeholder="$t(placeHolder)" :disabled="isLoading" />
    <LoaderIcon v-if="isSearching" />
    <SearchIcon v-else />
    <div v-if="searchQuery"><XIcon @click="clearSearch" /></div>
  </SearchStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import VueDebounce from 'vue-debounce'
import { flexCenter } from '@styles/mixins'
import { SearchIcon, LoaderIcon, XIcon } from 'vue-feather-icons'

const SearchStyled = styled('div')`
  ${flexCenter}
  position: relative;
  font-weight: 100;
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  input {
    flex-grow: 1;
    padding-left: 3rem;
    height: 3rem;
    border: none;
    color: ${({ theme }) => (theme.isDark ? theme.colors.navFontNormal : theme.colors.primary)};
    background: ${p => chroma(p.theme.colors.navFontNormal).alpha(0.2).css()};
    &:focus {
      outline: none;
      border: none;
    }
    ::placeholder {
      color: ${p => chroma(p.theme.colors.navFontNormal).alpha(0.7).css()};
    }
  }
  .feather-search,
  .feather-loader {
    position: absolute;
    left: 0.5rem;
    color: ${({ theme }) => theme.colors.primary};

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    &.feather-loader {
      animation: spin 1s linear infinite;
    }
  }
  > div {
    background: ${p => p.theme.colors.atomic.circleIconBG};
    position: absolute;
    border-radius: 50%;
    display: flex;
    right: 0.5rem;
    width: 24px;
    height: 24px;
    > .feather-x {
      cursor: pointer;
      height: 1rem;
      align-self: center;
      color: ${({ theme }) => theme.colors.primary};
      stroke-width: 3;
      &:hover {
        color: ${props => props.theme.colors.primaryActive};
      }
    }
  }
`

export default {
  props: {
    searchQuery: {
      type: String,
      required: true,
    },
    placeHolder: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
    },
  },
  components: {
    SearchStyled,
    SearchIcon,
    LoaderIcon,
    XIcon,
  },
  data() {
    return {
      isSearching: false,
    }
  },
  directives: {
    debounce: VueDebounce,
  },
  computed: {
    localSearchQuery: {
      get() {
        return this.searchQuery
      },
      set(value) {
        this.$emit('filterShareList', value)
      },
    },
  },
  methods: {
    clearSearch() {
      this.localSearchQuery = ''
    },
  },
}
</script>
