<template>
  <DashboardSharableListStyled>
    <UserWrapperStyled>
      <AvatarBadge :size="'30px'" :fontSize="'14px'" :userData="userMapped" :isOwner="false" />
      <UserStyled>
        <div id="sensitive">{{ user.username }}</div>
        <div id="sensitive" class="sub-text">{{ user.email }}</div>
      </UserStyled>
    </UserWrapperStyled>
    <DeleteIcon v-if="!user.isOwner && !user.allUsersList" class="delete" @click="deleteDashboardShares(user.shareId)" />
    <OwnerBadge v-if="user.isOwner" />
    <PlusIcon class="icon" v-if="user.allUsersList" @click="createDashboardShare(user.id)" />
  </DashboardSharableListStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import debounce from 'lodash/debounce'

import AvatarBadge from '@/components/Atomic/Atoms/AvatarBadge'
import { PlusIcon, DeleteIcon } from 'vue-feather-icons'
import OwnerBadge from '@/components/Atomic/Atoms/OwnerBadge'
import DASHBOARD_SHARE_LIST_QUERY from '#/graphql/operations/dashboard/share/dashboardShares.gql'
import CREATE_DASHBOARD_SHARE_MUTATION from '#/graphql/operations/dashboard/share/createDashboardShare.gql'
import DELETE_DASHBOARD_SHARE_MUTATION from '#/graphql/operations/dashboard/share/deleteDashboardShare.gql'
import { getUserIdFromToken } from '@common/utils'

const DashboardSharableListStyled = styled('div')`
  width: 100%;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0rem;

  .icon {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
  }
  .delete {
    cursor: pointer;
    fill: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.solidBG};
  }
`
const UserStyled = styled('div')`
  .sub-text {
    font-size: 10px;
  }
`

const UserWrapperStyled = styled('div')`
  display: grid;
  grid-template-columns: 3rem auto;
  align-items: center;
`
export default {
  props: {
    dashboardID: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  components: {
    DashboardSharableListStyled,
    UserWrapperStyled,
    AvatarBadge,
    PlusIcon,
    UserStyled,
    DeleteIcon,
    OwnerBadge,
  },
  computed: {
    userMapped() {
      const user = { ...this.user }
      const uiSettings = get(user, 'uiSettings', null)
      if (uiSettings === null) {
        user.uiSettings = { avatarColor: 'primaryBlue' }
        return user
      }
      return this.user
    },
  },
  methods: {
    async deleteDashboardShares(dashboardShareId) {
      await this.$apollo.mutate({
        mutation: DELETE_DASHBOARD_SHARE_MUTATION,
        variables: {
          where: {
            id: dashboardShareId,
          },
        },
        update: (store, { data: { deleteDashboardShare } }) => {
          const data = store.readQuery({
            query: DASHBOARD_SHARE_LIST_QUERY,
            variables: {
              where: {
                id: this.dashboardID,
              },
            },
          })
          data.dashboard.shares = data.dashboard.shares.filter(d => d.id !== deleteDashboardShare.id)
          store.writeQuery({ query: DASHBOARD_SHARE_LIST_QUERY, data })
        },
      })
    },
    createDashboardShare: debounce(
      async function (userId) {
        await this.$apollo.mutate({
          mutation: CREATE_DASHBOARD_SHARE_MUTATION,
          variables: {
            data: {
              dashboard: {
                connect: {
                  id: this.dashboardID,
                },
              },
              canWrite: true, // TODO: modify this based on logic
              sharedWithUserId: userId,
            },
            where: {
              srcUserId: getUserIdFromToken(this.$keycloak.token),
              targetUserId: userId,
            },
          },
          update: (store, { data: { createDashboardShare } }) => {
            const data = store.readQuery({
              query: DASHBOARD_SHARE_LIST_QUERY,
              variables: {
                where: {
                  id: this.dashboardID,
                },
              },
            })
            data.dashboard.shares.push(createDashboardShare)
            store.writeQuery({ query: DASHBOARD_SHARE_LIST_QUERY, data })
          },
        })
      },
      500,
      {
        leading: true,
        trailing: false,
      },
    ),
  },
}
</script>
