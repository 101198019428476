<template>
  <DashboardShareOverlayStyled>
    <TalpaLoaderWrapper v-if="$apollo.loading" />
    <Modal v-else class="modal">
      <template v-slot:header>
        <div class="uppercase">{{ $t('actions.share') | capitalize }}</div>
        <SearchBar
          :searchQuery="searchQuery"
          @filterShareList="usersListFiltered"
          :placeHolder="'dashboard.attributes.shareDashboardWith'"
        />
        <HorizontalTabs :tabs="tabs" @activateTab="activateTab" />
      </template>
      <template v-slot:main>
        <NoDataStyled v-if="selectedUsersList.length < 1"> {{ $t('dashboard.attributes.noUsersFound') }} </NoDataStyled>
        <DashboardSharableList v-else v-for="data in selectedUsersList" :key="data.id" :user="data" :dashboardID="dashboardID" />
      </template>
    </Modal>
  </DashboardShareOverlayStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import { flexCenter } from '@styles/mixins'
import { Modal, TalpaLoaderWrapper } from '@common/components'
import SearchBar from '@/components/Atomic/Molecules/SearchBar'
import DashboardSharableList from '@/components/Atomic/Organisms/DashboardShare/DashboardSharableList'
import HorizontalTabs from '@/components/Atomic/Atoms/HorizontalTabs'
import DASHBOARD_SHAREABLE_USERS from '#/graphql/organizations/dashboardShareableUsers.gql'
import DASHBOARD_SHARE_LIST_QUERY from '#/graphql/operations/dashboard/share/dashboardShares.gql'

const DashboardShareOverlayStyled = styled('div')`
  width: 65%;
  height: calc(100% - 1rem - 50px);
  max-width: 600px;
  padding: 1rem;
  padding-top: 50px;
  .modal {
    width: 100%;
    height: 100%;
    border-radius: 10px;

    > header {
      padding-left: 1rem;
      border-bottom: none;
      display: grid;
    }
    > main {
      padding: 0px 1rem 1rem 1rem;
      overflow: auto;
    }
  }
  .uppercase {
    text-transform: uppercase;
    padding-bottom: 1rem;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`
const NoDataStyled = styled('div')`
  ${flexCenter}
  height: 50%;
`

export default {
  props: {
    args: {
      type: Object,
    },
  },
  components: {
    DashboardShareOverlayStyled,
    Modal,
    SearchBar,
    DashboardSharableList,
    HorizontalTabs,
    TalpaLoaderWrapper,
    NoDataStyled,
  },
  data() {
    return {
      dashboardShareableUsers: [],
      activeTabID: 1,
      searchQuery: '',
      dashboard: null,
    }
  },
  computed: {
    allUsers() {
      const userDataMapped = this.dashboardShareableUsers.map(({ user }) => {
        return {
          ...user,
          isOwner: false,
          allUsersList: true,
        }
      })
      return userDataMapped.filter(user => !this.sharedWithUsers.some(sharedWithUser => user.id === sharedWithUser.id))
    },
    sharedWithUsers() {
      const dashboard = { ...this.dashboard }
      const owner = get(dashboard, 'owner', {})
      owner.isOwner = true
      const sharedList = get(dashboard, 'shares', []).map(s => {
        return {
          shareId: s.id,
          ...s.sharedWithUser,
        }
      })
      const mappedList = [...sharedList, owner]
      const sortedList = sortBy(mappedList, ({ isOwner }) => (isOwner ? 0 : 1))
      return sortedList
    },
    selectedUsersList() {
      const users = this.activeTabID === 1 ? this.sharedWithUsers : this.allUsers
      if (this.searchQuery !== '' && users.length > 0) {
        return users.filter(
          f =>
            f.username.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            f.email.toLowerCase().includes(this.searchQuery.toLowerCase()),
        )
      }
      return users
    },
    dashboardID() {
      return get(this.args, 'id', null)
    },
    sharedWithUsersCount() {
      return this.sharedWithUsers?.filter(user => !user?.isOwner)?.length
    },
    tabs() {
      const tabs = [
        {
          id: 1,
          name: 'sharedWith',
          count: this.sharedWithUsersCount,
        },
        {
          id: 2,
          name: 'allUsers',
        },
      ]
      return tabs.map(tab => {
        return {
          ...tab,
          isSelected: this.activeTabID === tab.id,
        }
      })
    },
  },
  methods: {
    activateTab(tabID) {
      this.activeTabID = tabID
    },
    usersListFiltered(value) {
      this.searchQuery = value
    },
  },
  apollo: {
    dashboardShareableUsers: {
      query: DASHBOARD_SHAREABLE_USERS,
    },
    dashboard: {
      query: DASHBOARD_SHARE_LIST_QUERY,
      variables() {
        return {
          where: {
            id: this.dashboardID,
          },
        }
      },
      skip() {
        return !this.dashboardID
      },
    },
  },
}
</script>
