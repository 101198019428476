var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SearchStyled', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.localSearchQuery,
      expression: "localSearchQuery"
    }, {
      name: "debounce",
      rawName: "v-debounce:300ms",
      value: _vm.localSearchQuery,
      expression: "localSearchQuery",
      arg: "300ms"
    }],
    attrs: {
      "placeholder": _vm.$t(_vm.placeHolder),
      "disabled": _vm.isLoading
    },
    domProps: {
      "value": _vm.localSearchQuery
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.localSearchQuery = $event.target.value;
      }
    }
  }), _vm.isSearching ? _c('LoaderIcon') : _c('SearchIcon'), _vm.searchQuery ? _c('div', [_c('XIcon', {
    on: {
      "click": _vm.clearSearch
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }