var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HorizontalTabsStyled', _vm._l(_vm.tabs, function (tab) {
    return _c('TabStyled', {
      key: tab.id,
      attrs: {
        "isSelected": tab.isSelected
      },
      on: {
        "click": function click($event) {
          return _vm.activateTab(tab.id);
        }
      }
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("dashboard.attributes." + tab.name)) + " ")]), tab.count ? _c('span', [_vm._v(" (" + _vm._s(tab.count) + ") ")]) : _vm._e()]);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }